.Path {
    padding: 0 2%;
    margin-top: 3em;
}

.experience-card {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    border-radius:5px;
    width: 900px;
    padding: 10px;
    background: rgb(60, 62, 68);
    box-shadow: rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px;
}

.experience {
    text-align: left;
}

.experience__enterprise {
    margin: 0px;
}

.experience__position {
    color: #FFE400;
}

.app-titles {
    color: #FFE400;
    margin-bottom: 0px;
}

@media (max-width: 1000px) {
    .experience-card {
        width: auto;
    }
}