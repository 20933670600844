.Info {
    margin-top: 20px;
    padding: 0 2%;
}

.app-titles {
    color: #FFE400;
}

ul {
    text-align: left;
}

h2 {
    margin-top: 3em;
}