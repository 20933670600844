.Technologies {
    padding: 0% 2%;
    width: 900px;
    margin-left: auto;
    margin-right: auto;
}

.app-titles {
    color: #FFE400;
}

ul {
    text-align: left;
}

h2 {
    margin-top: 2.5em;
}

@media (max-width: 1000px) {
    .Technologies {
        width: auto;
    }
}