.social-media {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.social-media__icon {
    margin: 5px;
    padding: 10px;
    background: rgb(60, 62, 68);
    box-shadow: rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px;
    border-radius: 5px;
    cursor: pointer;
}

.app-titles {
    color: #FFE400;
}