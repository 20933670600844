.App {
  text-align: center;
  min-height: 100vh;
  background-color: rgb(32, 35, 41);
  color: white;
  padding: 1.5% 0%;
}

.life-philosophy {
  margin-top: 3em;
}
