.image-content {
    width: 100%;
    display: flex;
    justify-content: center;
}

.image {
    width: 350px;
    height: auto;
    border-radius: 50%;
}

@media (max-width: 700px) {
    .image {
        width: 80%;
        height: auto;   
    }
}